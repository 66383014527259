<template>
    <div>
        <page-header
            :title="pageHeaderTitle"
            data-testid="close-account-payoff-header"
            show-back
            @onClose="$router.go(-1)"
        />
        <error-view
            v-show="error"
            image-path-in-assets="error-icon.svg"
            :title="errorTitle"
            :description="errorDescription"
            :cta="errorCTA"
            @click="retryOnError"
        />
        <loading-indicator
            v-show="loading"
            :title="$t('global.loadingMessage.loading')"
        />
        <div
            v-if="!error && !loading && !isZeroBalancePayoff"
            class="container"
        >
            <div
                class="card border-0 p-2 mb-3 mt-3 bg-light"
                data-testid="close-account-payoff-details"
            >
                <div class="d-flex justify-content-between mb-2">
                    <div class="text-start">
                        <span> Current Balance </span>
                    </div>
                    <div class="text-end">
                        <span>
                            {{ toFormattedUSD(payoffDetails.totalCurrentBalance) }}
                        </span>
                    </div>
                </div>
                <div class="d-flex justify-content-between mb-2">
                    <div class="text-start">
                        <span> Interest & Fees </span>
                    </div>
                    <div class="text-end">
                        <span>
                            {{ toFormattedUSD(payoffDetails.interestAndFees) }}
                        </span>
                    </div>
                </div>

                <span class="border-bottom mb-2" />

                <div class="d-flex justify-content-between mb-2">
                    <div class="text-start">
                        <span class="fw-bold"> Total Amount Due </span>
                    </div>
                    <div class="text-end">
                        <span class="fw-bold">
                            {{ toFormattedUSD(payoffDetails.totalPayoffAmount) }}
                        </span>
                    </div>
                </div>

                <div class="d-flex justify-content-between">
                    <div class="text-start">
                        <span> Date Due By </span>
                    </div>
                    <div class="text-end">
                        <span>
                            {{ payoffDateFormatted }}
                        </span>
                    </div>
                </div>
            </div>
            <div data-testid="close-account-payoff-details-text">
                <span v-html="$t('pages.closeAccount.payoffDetails.text', { tomorrow: payoffDateFormatted })" />
            </div>
            <div class="text-center mt-3">
                <span> Pay Off Document:&nbsp; </span>
                <div
                    v-if="!isWebView"
                    style="display: inline-block"
                >
                    <span
                        class="align-self-center"
                        data-testid="close-account-payoff-download"
                        style="cursor: pointer"
                        @click="downloadPayoffQuote"
                    >
                        <u>Download</u>
                    </span>
                    <span
                        v-show="isDownloadingPayoffQuote"
                        class="spinner-border spinner-border-sm text-secondary mx-1"
                    />
                    &nbsp;|&nbsp;
                    <span
                        class="align-self-center"
                        data-testid="close-account-payoff-email"
                        style="cursor: pointer"
                        @click="goToEmailPage"
                    >
                        <u>Email</u>
                    </span>
                </div>
                <div
                    v-else
                    style="display: inline-block"
                >
                    <span
                        class="align-self-center"
                        style="cursor: pointer"
                        @click="goToEmailPage"
                    >
                        <u>Email</u>
                    </span>
                </div>
            </div>
            <div
                v-show="errorPdfDownload"
                class="alert alert-warning text-center mt-1"
                role="alert"
            >
                <span>
                    {{ errorPdfDownload }}
                </span>
            </div>
            <base-button
                button-classes="btn btn-primary mt-4"
                data-testid="close-account-payoff-continue-button"
                @click="handleClickButton"
            >
                Continue
            </base-button>
        </div>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import { navigation } from '@/mixins/navigation'
    import { RouteNames } from '@/routes/router'
    import BaseButton from '@/components/base/BaseButton'
    import { mapActions, mapGetters } from 'vuex'
    import { logger } from '@/utils/logger'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import format from '@/mixins/format'
    import { accountPayoffQuotePdf } from '@/services/api'
    import ErrorView from '@/components/ErrorView'
    import { i18n } from '@/utils/i18n'
    import { isSafari } from '@/utils/parseUserAgents'
    import generic from '@/utils/generic'
    import { NYC_TIMEZONE } from '@/data/constants'

    const ERROR_PAYOFF_DATE_BEYOND_STATEMENT = 'ERROR_PAYOFF_DATE_BEYOND_STATEMENT'

    export default {
        name: 'CloseAccountPayoffDetails',
        mixins: [format, navigation],
        components: {
            LoadingIndicator,
            PageHeader,
            BaseButton,
            ErrorView,
        },
        mounted: async function () {
            this.$logEvent('view_close_account_payoff_details')
            await this.getPayoffDetailsEndOfBillingCycle()
            if (this.isZeroBalancePayoff) {
                await this.$router.replace({ name: RouteNames.CLOSE_ACCOUNT_WIRE })
                return
            }
        },
        data() {
            return {
                errorTitle: '',
                errorDescription: '',
                errorCTA: '',
                errorPdfDownload: '',
                isDownloadingPayoffQuote: false,
            }
        },
        computed: {
            ...mapGetters({
                loading: 'loading',
                error: 'error',
                customerLastName: 'lastName',
                payoffDetails: 'payoffDetails',
                isZeroBalancePayoff: 'isZeroBalancePayoff',
                isWebView: 'isWebView',
                billingDay: 'billingDay',
            }),
            pageHeaderTitle: function () {
                return !this.loading && !this.isZeroBalancePayoff ? 'Pay Off Details' : ''
            },
            payoffDateFormatted: function () {
                return generic.convertDateTimeFormat(this.payoffDetails.payoffDateInNycTime, { inputTimezone: NYC_TIMEZONE, outputTimezone: NYC_TIMEZONE, outputFormat: 'MM/DD/YYYY (z)' })
            },
            todayDateInNycTime: function () {
                return generic.getCurrentJSDateInNycTz()
            },
            endOfCurrentBillingCycleDateInNycTime: function () {
                logger.info(`billingDay: ${this.billingDay}`)
                return generic.getGivenFutureOrCurrentDayJSDateInNycTz(this.billingDay)
            },
        },
        watch: {
            error: function (value) {
                if (value === '') {
                    this.errorTitle = ''
                    this.errorDescription = ''
                    this.errorCTA = ''
                } else if (value === ERROR_PAYOFF_DATE_BEYOND_STATEMENT) {
                    this.errorTitle = i18n.t('pages.closeAccount.payoffDetails.errors.payoffDateBeyondStatementTitle')
                    this.errorDescription = i18n.t('pages.closeAccount.payoffDetails.errors.payoffDateBeyondStatementDesc')
                    this.errorCTA = ''
                } else {
                    this.errorTitle = i18n.t('pages.closeAccount.payoffDetails.errors.genericTitle')
                    this.errorDescription = i18n.t('pages.closeAccount.payoffDetails.errors.genericDesc')
                    this.errorCTA = i18n.t('global.cta.retry')
                }
            },
        },
        methods: {
            ...mapActions(['getPayoffQuote']),
            getPayoffDetailsEndOfBillingCycle: async function () {
                logger.info('Getting payoff details for end of current billing cycle')
                logger.info(`endOfCurrentBillingCycleDateInNycTime: ${this.endOfCurrentBillingCycleDateInNycTime.toString()}`)
                await this.getPayoffQuote({ requestDateInNycTime: this.todayDateInNycTime, payoffDateInNycTime: this.endOfCurrentBillingCycleDateInNycTime })
            },
            downloadPayoffQuote: async function () {
                this.$logEvent('click_close_account_payoff_quote_download')
                try {
                    if (!this.isWebView) {
                        logger.info(`browser, download Payoff Quote Pdf`)
                        this.errorPdfDownload = ''
                        this.isDownloadingPayoffQuote = true
                        const payoffQuoteBlob = await accountPayoffQuotePdf(this.todayDateInNycTime, this.endOfCurrentBillingCycleDateInNycTime)
                        const pdfDoc = payoffQuoteBlob.data
                        //Create a Blob from the PDF Stream
                        const file = new Blob([pdfDoc], { type: 'application/pdf' })
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file)
                        if (isSafari()) {
                            window.location.href = fileURL
                        } else {
                            window.open(fileURL, '_blank')
                        }
                        this.isDownloadingPayoffQuote = false
                    }
                } catch (e) {
                    this.errorPdfDownload = 'Failed to download payoff quote'
                    this.isDownloadingPayoffQuote = false
                }
            },
            goToEmailPage: async function () {
                this.$logEvent('click_close_account_email_payoff')
                await this.$router.push({ name: RouteNames.CLOSE_ACCOUNT_EMAIL_PAYOFF })
            },
            retryOnError: async function () {
                this.$logEvent('click_close_account_payoff_retry')
                await this.getPayoffDetailsEndOfBillingCycle()
                if (this.isZeroBalancePayoff) {
                    await this.$router.replace({ name: RouteNames.CLOSE_ACCOUNT_WIRE })
                    return
                }
            },
            handleClickButton: async function () {
                this.$logEvent('click_close_account_payoff_continue')
                await this.$router.push({ name: RouteNames.CLOSE_ACCOUNT_WIRE })
            },
        },
    }
</script>
